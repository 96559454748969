.main {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.form {
  max-width: 40vw;
}

img {
  width: 400px;
  height: 60px;
}

.header {
  min-height: 10vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.box {
  max-width: 80vw;
  margin: auto;
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (max-width: 800px) {

  img {
    width: 200px;
    height: 40px;
  }

  .form {
    max-width: 80vw;
    width: auto;
  }
  .box {
    max-width: 80vw;
    min-width: 246px;
  }
}
