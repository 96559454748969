.footer {
    width: 100%;
    background: black;
    min-height: 80px;
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.footer h1, h6 {
    margin-bottom: 0;
    margin-left: 20px;
}

li {
    list-style: none;
}

a:hover {
    color: #3e5aad;
    -webkit-transition: color .4s ease; 
    -o-transition: color .4s ease; 
    transition: color .4s ease;
}

td a {
    text-decoration: none;
}

.columnContainer {
    display: flex;
    flex-direction: column;
}

.listBox {
    padding-left: 10px;
    border-left: 1px solid white;
}

.socialAndContact {
    width: 50%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.columnContainer label {
    font-size: 17px;
    margin-bottom: 12px;
}

.facebookIcon {
    margin-right: 9px;
}

.linkedinIcon {
    margin-right: 5px;
}

.twitterIcon {
    margin-right: 3px;
}

label.standardCursor {
    cursor: auto;
}

@media (min-width: 799px) {
    .footer {
        min-height: 150px;
    }
}

@media (max-width: 499px) {
    .footer {
        flex-direction: column;
        align-items: flex-start;
        padding-left: 30px;
        padding-top: 15px;
    }
    .socialAndContact {
        margin-top: 20px;
        flex-direction: column;
        justify-content: space-around;
    }
    .footer h1, h6 {
        margin-left: 0;
    }
    .listBox {
        border: none;
        padding: 0;
        margin-bottom: 20px;
    }
    .columnContainer label {
        margin-bottom: 5px;
    }
}