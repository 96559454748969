
.main {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.form {
  max-width: 40vw;
}

img {
  width: 400px;
  height: 60px;
}

.header {
  min-height: 10vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.box {
  max-width: 80vw;
  margin: auto;
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (max-width: 800px) {

  img {
    width: 200px;
    height: 40px;
  }

  .form {
    max-width: 80vw;
    width: auto;
  }
  .box {
    max-width: 80vw;
    min-width: 246px;
  }
}

/*
  Code by Luke Haas @lukehaas
  https://projects.lukehaas.me/css-loaders/
 */

/*
   colors:
   blue-hue: #405ba9
   gray-hue: #eeeeee
 */

 .spinner {
  margin: 100px auto;
  font-size: 25px;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  -webkit-animation: load5 1.1s infinite ease;
  animation: load5 1.1s infinite ease;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}
@-webkit-keyframes load5 {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #405ba9, 1.8em -1.8em 0 0em rgba(64,91,169, 0.2), 2.5em 0em 0 0em rgba(64,91,169, 0.2), 1.75em 1.75em 0 0em rgba(64,91,169, 0.2), 0em 2.5em 0 0em rgba(64,91,169, 0.2), -1.8em 1.8em 0 0em rgba(64,91,169, 0.2), -2.6em 0em 0 0em rgba(64,91,169, 0.5), -1.8em -1.8em 0 0em rgba(64,91,169, 0.7);
  }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(64,91,169, 0.7), 1.8em -1.8em 0 0em #405ba9, 2.5em 0em 0 0em rgba(64,91,169, 0.2), 1.75em 1.75em 0 0em rgba(64,91,169, 0.2), 0em 2.5em 0 0em rgba(64,91,169, 0.2), -1.8em 1.8em 0 0em rgba(64,91,169, 0.2), -2.6em 0em 0 0em rgba(64,91,169, 0.2), -1.8em -1.8em 0 0em rgba(64,91,169, 0.5);
  }
  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(64,91,169, 0.5), 1.8em -1.8em 0 0em rgba(64,91,169, 0.7), 2.5em 0em 0 0em #405ba9, 1.75em 1.75em 0 0em rgba(64,91,169, 0.2), 0em 2.5em 0 0em rgba(64,91,169, 0.2), -1.8em 1.8em 0 0em rgba(64,91,169, 0.2), -2.6em 0em 0 0em rgba(64,91,169, 0.2), -1.8em -1.8em 0 0em rgba(64,91,169, 0.2);
  }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(64,91,169, 0.2), 1.8em -1.8em 0 0em rgba(64,91,169, 0.5), 2.5em 0em 0 0em rgba(64,91,169, 0.7), 1.75em 1.75em 0 0em #405ba9, 0em 2.5em 0 0em rgba(64,91,169, 0.2), -1.8em 1.8em 0 0em rgba(64,91,169, 0.2), -2.6em 0em 0 0em rgba(64,91,169, 0.2), -1.8em -1.8em 0 0em rgba(64,91,169, 0.2);
  }
  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(64,91,169, 0.2), 1.8em -1.8em 0 0em rgba(64,91,169, 0.2), 2.5em 0em 0 0em rgba(64,91,169, 0.5), 1.75em 1.75em 0 0em rgba(64,91,169, 0.7), 0em 2.5em 0 0em #405ba9, -1.8em 1.8em 0 0em rgba(64,91,169, 0.2), -2.6em 0em 0 0em rgba(64,91,169, 0.2), -1.8em -1.8em 0 0em rgba(64,91,169, 0.2);
  }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(64,91,169, 0.2), 1.8em -1.8em 0 0em rgba(64,91,169, 0.2), 2.5em 0em 0 0em rgba(64,91,169, 0.2), 1.75em 1.75em 0 0em rgba(64,91,169, 0.5), 0em 2.5em 0 0em rgba(64,91,169, 0.7), -1.8em 1.8em 0 0em #405ba9, -2.6em 0em 0 0em rgba(64,91,169, 0.2), -1.8em -1.8em 0 0em rgba(64,91,169, 0.2);
  }
  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(64,91,169, 0.2), 1.8em -1.8em 0 0em rgba(64,91,169, 0.2), 2.5em 0em 0 0em rgba(64,91,169, 0.2), 1.75em 1.75em 0 0em rgba(64,91,169, 0.2), 0em 2.5em 0 0em rgba(64,91,169, 0.5), -1.8em 1.8em 0 0em rgba(64,91,169, 0.7), -2.6em 0em 0 0em #405ba9, -1.8em -1.8em 0 0em rgba(64,91,169, 0.2);
  }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(64,91,169, 0.2), 1.8em -1.8em 0 0em rgba(64,91,169, 0.2), 2.5em 0em 0 0em rgba(64,91,169, 0.2), 1.75em 1.75em 0 0em rgba(64,91,169, 0.2), 0em 2.5em 0 0em rgba(64,91,169, 0.2), -1.8em 1.8em 0 0em rgba(64,91,169, 0.5), -2.6em 0em 0 0em rgba(64,91,169, 0.7), -1.8em -1.8em 0 0em #405ba9;
  }
}
@keyframes load5 {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #405ba9, 1.8em -1.8em 0 0em rgba(64,91,169, 0.2), 2.5em 0em 0 0em rgba(64,91,169, 0.2), 1.75em 1.75em 0 0em rgba(64,91,169, 0.2), 0em 2.5em 0 0em rgba(64,91,169, 0.2), -1.8em 1.8em 0 0em rgba(64,91,169, 0.2), -2.6em 0em 0 0em rgba(64,91,169, 0.5), -1.8em -1.8em 0 0em rgba(64,91,169, 0.7);
  }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(64,91,169, 0.7), 1.8em -1.8em 0 0em #405ba9, 2.5em 0em 0 0em rgba(64,91,169, 0.2), 1.75em 1.75em 0 0em rgba(64,91,169, 0.2), 0em 2.5em 0 0em rgba(64,91,169, 0.2), -1.8em 1.8em 0 0em rgba(64,91,169, 0.2), -2.6em 0em 0 0em rgba(64,91,169, 0.2), -1.8em -1.8em 0 0em rgba(64,91,169, 0.5);
  }
  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(64,91,169, 0.5), 1.8em -1.8em 0 0em rgba(64,91,169, 0.7), 2.5em 0em 0 0em #405ba9, 1.75em 1.75em 0 0em rgba(64,91,169, 0.2), 0em 2.5em 0 0em rgba(64,91,169, 0.2), -1.8em 1.8em 0 0em rgba(64,91,169, 0.2), -2.6em 0em 0 0em rgba(64,91,169, 0.2), -1.8em -1.8em 0 0em rgba(64,91,169, 0.2);
  }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(64,91,169, 0.2), 1.8em -1.8em 0 0em rgba(64,91,169, 0.5), 2.5em 0em 0 0em rgba(64,91,169, 0.7), 1.75em 1.75em 0 0em #405ba9, 0em 2.5em 0 0em rgba(64,91,169, 0.2), -1.8em 1.8em 0 0em rgba(64,91,169, 0.2), -2.6em 0em 0 0em rgba(64,91,169, 0.2), -1.8em -1.8em 0 0em rgba(64,91,169, 0.2);
  }
  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(64,91,169, 0.2), 1.8em -1.8em 0 0em rgba(64,91,169, 0.2), 2.5em 0em 0 0em rgba(64,91,169, 0.5), 1.75em 1.75em 0 0em rgba(64,91,169, 0.7), 0em 2.5em 0 0em #405ba9, -1.8em 1.8em 0 0em rgba(64,91,169, 0.2), -2.6em 0em 0 0em rgba(64,91,169, 0.2), -1.8em -1.8em 0 0em rgba(64,91,169, 0.2);
  }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(64,91,169, 0.2), 1.8em -1.8em 0 0em rgba(64,91,169, 0.2), 2.5em 0em 0 0em rgba(64,91,169, 0.2), 1.75em 1.75em 0 0em rgba(64,91,169, 0.5), 0em 2.5em 0 0em rgba(64,91,169, 0.7), -1.8em 1.8em 0 0em #405ba9, -2.6em 0em 0 0em rgba(64,91,169, 0.2), -1.8em -1.8em 0 0em rgba(64,91,169, 0.2);
  }
  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(64,91,169, 0.2), 1.8em -1.8em 0 0em rgba(64,91,169, 0.2), 2.5em 0em 0 0em rgba(64,91,169, 0.2), 1.75em 1.75em 0 0em rgba(64,91,169, 0.2), 0em 2.5em 0 0em rgba(64,91,169, 0.5), -1.8em 1.8em 0 0em rgba(64,91,169, 0.7), -2.6em 0em 0 0em #405ba9, -1.8em -1.8em 0 0em rgba(64,91,169, 0.2);
  }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(64,91,169, 0.2), 1.8em -1.8em 0 0em rgba(64,91,169, 0.2), 2.5em 0em 0 0em rgba(64,91,169, 0.2), 1.75em 1.75em 0 0em rgba(64,91,169, 0.2), 0em 2.5em 0 0em rgba(64,91,169, 0.2), -1.8em 1.8em 0 0em rgba(64,91,169, 0.5), -2.6em 0em 0 0em rgba(64,91,169, 0.7), -1.8em -1.8em 0 0em #405ba9;
  }
}

.footer {
    width: 100%;
    background: black;
    min-height: 80px;
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.footer h1, h6 {
    margin-bottom: 0;
    margin-left: 20px;
}

li {
    list-style: none;
}

a:hover {
    color: #3e5aad; 
    transition: color .4s ease;
}

td a {
    text-decoration: none;
}

.columnContainer {
    display: flex;
    flex-direction: column;
}

.listBox {
    padding-left: 10px;
    border-left: 1px solid white;
}

.socialAndContact {
    width: 50%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.columnContainer label {
    font-size: 17px;
    margin-bottom: 12px;
}

.facebookIcon {
    margin-right: 9px;
}

.linkedinIcon {
    margin-right: 5px;
}

.twitterIcon {
    margin-right: 3px;
}

label.standardCursor {
    cursor: auto;
}

@media (min-width: 799px) {
    .footer {
        min-height: 150px;
    }
}

@media (max-width: 499px) {
    .footer {
        flex-direction: column;
        align-items: flex-start;
        padding-left: 30px;
        padding-top: 15px;
    }
    .socialAndContact {
        margin-top: 20px;
        flex-direction: column;
        justify-content: space-around;
    }
    .footer h1, h6 {
        margin-left: 0;
    }
    .listBox {
        border: none;
        padding: 0;
        margin-bottom: 20px;
    }
    .columnContainer label {
        margin-bottom: 5px;
    }
}
